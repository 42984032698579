import React from "react"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"

import SimpleLayout from "../Layout/SimpleLayout"
import Pontuation from "../Pontuation/Pontuation"

import styles from "./styles/contact.module.css"

const Contact = ({ image, ...props }) => {
  return (
    <SimpleLayout path={props.path}>
      <section className={styles.section}>
        <div className={styles.container}>
          <div className={`${styles.col} ${styles.left}`}>
            <Img fixed={image.fixed} className={styles.image} />
            <h1 className={styles.sectionTitle}>
              Let's build amazing things together
              <Pontuation>.</Pontuation>
            </h1>
            <h2 className={styles.sectionDescription}>
              You will find the best business partners here.
            </h2>
          </div>
          <div className={styles.col}>
            <div className={`${styles.col} ${styles.card}`}>
              <h3>
                Contact us<Pontuation>.</Pontuation>
              </h3>
              <a href="mailto:geral@leaplane.io" className={styles.contact}>
                <FontAwesomeIcon
                  style={{ opacity: 0.8, fontSize: 13, marginRight: 10 }}
                  icon={faEnvelope}
                />
                <p>geral@leaplane.io</p>
              </a>
              <a href="tel:+351919030468" className={styles.contact}>
                <FontAwesomeIcon
                  style={{ opacity: 0.8, fontSize: 13, marginRight: 10 }}
                  icon={faPhone}
                />
                <p>+351 919 030 468</p>
              </a>
            </div>
            <div className={`${styles.col} ${styles.card}`}>
              <h3>
                Meet us<Pontuation>.</Pontuation>
              </h3>
              <p>Centro Empresarial da Lionesa, C27</p>
              <p>4470-294, Leça do Balio</p>
              <p>Matosinhos, Portugal</p>
            </div>
            <div className={`${styles.col} ${styles.card}`}>
              <h3>
                Follow us<Pontuation>.</Pontuation>
              </h3>
              <div className={`${styles.row} ${styles.social}`}>
                <a
                  href="https://www.facebook.com/leaplanetech"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../../images/facebook-square.png")}
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/leaplane"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../../images/linkedin.png")}
                    alt="Linkedin"
                  />
                </a>
                <a href="/">
                  <img
                    src={require("../../images/instagram.png")}
                    alt="Instagram"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SimpleLayout>
  )
}

export default Contact
